.logo
  width: 80px

.container
  margin: 12vh 5vh 0 5vh

.title
  margin-top: 15vh
  text-align: center

.phase-red
  background: #c00000
  height: 25px
  border-radius: 5px
  color: #fff

.phase-turquoise
  background: #0034a7
  height: 25px
  border-radius: 5px
  color: #fff

.phase-gray
  background: #5b6f96
  height: 25px
  border-radius: 5px
  color: #fff

.phase-mustard
  background: #96cbff
  height: 25px
  border-radius: 5px
  color: blue

.phase-orange
  background: orange
  height: auto
  border-radius: 5px
  color: #fff

.phase-gold
  background: #dedede
  height: auto
  border-radius: 5px
  color: #000

.phase-blue
  background: #bad6b2
  height: auto
  border-radius: 5px
  color: green

.phase-pink
  background: #4fb03e
  height: auto
  border-radius: 5px
  color: #fff

.phase-green
  background: rgb(8,202,8)
  height: auto
  border-radius: 5px
  color: #fff

.phase_sg
  background: #dda0dd
  height: auto
  border-radius: 5px
  color: #fff

.phase-center
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  height: 25px
  border: 0

.contact-actions
  margin: 3vh 0 3.5vh 0
  text-align: right

.actions
  display: flex

.action-buttons
  margin: 0 4px 0 4px !important
  color: #fff


// Login
$gradient-start: hsla(127, 72%, 46%, 1)
$gradient-mid: hsla(0, 0%, 0%, 1)
$gradient-end: hsla(356, 72%, 49%, 1)

.login-container 
  display: flex
  height: 100vh
  width: 100%
  margin: 0
  padding: 0

  .left 
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    background: $gradient-start
    background: linear-gradient(45deg, $gradient-start 0%, $gradient-mid 50%, $gradient-end 100%)
    background: -moz-linear-gradient(45deg, $gradient-start 0%, $gradient-mid 50%, $gradient-end 100%)
    background: -webkit-linear-gradient(45deg, $gradient-start 0%, $gradient-mid 50%, $gradient-end 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#$gradient-start", endColorstr="#$gradient-end", GradientType=1)
    border-right: 1px solid #ccc

    .logo 
      width: 300px
      height: auto

  .right 
    flex: 1
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    padding: 0 25px
    gap: 3px

    .form-container 
      text-align: left

      > div 
        margin-bottom: 15px
      
      > button 
        justify-content: right
        text-align: right

.button-section 
  display: flex
  justify-content: flex-end


.pdf-icon

  &:hover
    cursor: pointer

.grey-icon
  color: grey

.red-icon
  color: red

.show-icon
  color: '#172D40'

  &:hover
    cursor: pointer
    background: #e3e3e3
    border-radius: 10px


.description-link
  text-decoration: none
  color: #0000EE

  &:hover
    color: #0000EE

  &:visited
    color: #0000EE
